<section id="home-offering-section" class="bg-color-secondary-lighter">
    <div class="container-fluid inner-section">
        <div class="row mb-4">
            <div class="col-12 text-center">
                <h1 class="color-secondary">Our Maternal Digital Solutions</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="glide-container">
                    <div class="glider__home__offering glide">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="product-container">
                                        <!-- <a routerLink="/offering/fetomax"> -->
                                            <div class="card">
                                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetosense.jpg">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">FetoMax</h4>
                                                    <h5 class="card-text color-secondary">AI Powered Digital CTG Machine</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Portable & wireless machine.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Remote monitoring & Unlimited test storage and WhatsApp sharing.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer  text-center">
                                                    <a routerLink="/offering/fetomax" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        <!-- </a> -->
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="product-container">
                                        <!-- <a routerLink="/offering/feto-twin"> -->
                                            <div class="card">
                                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetotwin.png">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">FetoTwin</h4>
                                                    <h5 class="card-text color-secondary">Wireless Twin Monitoring CTG Machine</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Dual Heart Monitoring.
                                                        </span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Single-Point Charging & Wireless compact kit.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/feto-twin" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        <!-- </a> -->
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="product-container">
                                        <!-- <a routerLink="/offering/feto-mini"> -->
                                            <div class="card">
                                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetomini.png">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">FetoMini</h4>
                                                    <h5 class="card-text color-secondary">Smartphone based CTG machine</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Portable & wireless machine.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Unlimited test storage and WhatsApp sharing.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/feto-mini" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        <!-- </a> -->
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="product-container">
                                        <!-- <a routerLink="/offering/babybeat"> -->
                                            <div class="card">
                                                <img class="card-img-top" alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../assets/images/home/products/babybeat.png">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">BabyBeat</h4>
                                                    <h5 class="card-text color-secondary">Experience your baby's every beat.</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush h-100">
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Wireless fetal heart monitor.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Medical grade DIY home monitoring.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Real time report sharing.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/babybeat" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        <!-- </a> -->
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="product-container">
                                        <!-- <a routerLink="/offering/anandimaa"> -->
                                            <div class="card">
                                                <img class="card-img-top" alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../assets/images/home/products/anandimaa.jpg">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">AnandiMaa</h4>
                                                    <h5 class="card-text color-secondary">Pregnancy care for rural mothers</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Empower healthcare providers.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Public health partnership model.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Provide pregnancy care to remotest area.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/anandimaa" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        <!-- </a> -->
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="product-container">
                                        <!-- <a routerLink="/offering/careHEr"> -->
                                            <div class="card">
                                                <img class="card-img-top hover-overlay shadow-1-strong" alt="Health Entrepreneurs enabling healthcare providers
                                                to offer quality care to women at last mile, CareMother" src="../../../../assets/images/home/products/careher.jpg">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">careHER</h4>
                                                    <h5 class="card-text color-secondary">Quality care to women at last mile</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Ecosystem for women at last mile.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Delivering diagnostic device.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Building entrepreneurs at grassroot level.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/careHEr" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        <!-- </a> -->
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" title="left-arrow">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" title="right-arrow">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div> 
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>